body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-img {
    max-width: '100%';
    max-height: 500;
}

.image-upload > input {
    visibility: hidden;
    width: 0;
    height: 0;
}

.ws-nowrap {
    white-space: nowrap;
}

.fs-12 {
    font-size: 12px;
}

.cursor-pointer {
    cursor: pointer;
}

textarea {
    resize: none;
}

.mt-5em {
    margin-top: 5em;
}

.dropdown-toggle::before {
    display: none !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.link {
    color: dimgrey;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

@media (min-width: 750px) {
    form {
        max-width: 75%;
        margin: auto;
    }
}

@media (min-width: 800px) {
    .button-container {
        max-width: 50%;
        margin: auto;
    }
}

@media (min-width: 1000px) {
    form {
        max-width: 55%;
        margin: auto;
    }
}
